import React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const Blogs = () => {
    const blogsData = [
        
        {
            title: "Benefits of living in a gated community in bangalore",
            description: "Like everything around us, living spaces are changing around the world with the rising environmental concerns. In architecture, traditional and sustainable materials are being utilized to curb the concrete and get closer to earth elements that are not only as durable but also more advantageous with their capabilities of climate control, proper ventilation, and more. Increasingly, luxury living in the cities is looking for a sense of space and calm that one usually finds missing in urban dwellings. A rooted experience in tradition and luxury, let's look at some masterpieces of materials that improve the scope of this breathable innovation.",
            image: "/previous1.png",
            date: "08",
            day: "Thu",
            link:"/blog/benefits-of-living-in-a-gated-community-in-bangalore"
        },
        {
            title: "Luxury villaments in bangalore with forest view",
            description: "Bangalore, often referred to as the Garden City of India, is a bustling metropolis known for its vibrant culture, booming tech industry, and pleasant weather. As the city grows, the demand for luxurious living spaces has risen, particularly those that offer a serene escape from the urban hustle. One of the most sought-after features in luxury villaments is a view of lush green forests, providing residents with a unique blend of urban convenience and natural tranquility. In this blog, we explore the allure of luxury villaments in Bangalore with a forest view, highlighting their benefits, features, and the lifestyle they offer.",
            image: "/ProjectImages/forestview7.webp",
            date: "12",
            day: "Mon",
            link:"/blog/luxury-villaments-in-bangalore-with-forest-view"
        },
        {
            title: "How physiological routines influence design",
            description: "The term ‘Circadian Rhythm’ refers to your daily cycle and everyday routine of the body. The concept holds immense value in human-centric design thinking where homes are strategically planned to influence behaviour as well as to improve the physiological functions of the body temperature, blood pressure, metabolic rate etc. This makes way for a comfortable experience that is responsive to your needs.",
            image: "/ProjectImages/SMERA_G_3.webp",
            date: "28",
            day: "Tue",
            link:"/blog/how-physiological-routines-influence-design-homes-that-build-good-health"
        },
        {
            title: "Sustainable luxury living",
            description: "Post-COVID, our workspaces have quickly adapted to hybrid and work-from-home models, and Namma Bengaluru is one of the most inspiring markets for sustainable living homes. At Gravity Homes, we meet homeowners actively seeking luxury and safety with a keen emphasis on connection to nature. Preserving greenery is a priority, and today, people dream of the calm of nature with lush surroundings that nurture their family’s lifestyles, also allowing quality moments with themselves.",
            image: "/Rectangle 36.webp",
            date: "08",
            day: "Mon",
            link:"/blog/sustainable-luxury-living-the-rise-of-eco-friendly-villas-in-bangalore"
        },
        {
            title: "Sustainable living materials that everyone is rooting for",
            description: "Like everything around us, living spaces are changing around the world with the rising environmental concerns. In architecture, traditional and sustainable materials are being utilised to curb the concrete and get closer to earth elements that are not only as durable but also more advantageous with their capabilities of climate control, proper ventilation and more. Increasingly, luxury living in the cities is looking for a sense of space and calm that one usually finds missing in urban dwellings. A rooted experience in tradition and luxury, let's look at some masterpieces of materials that improve the scope of this breathable innovation",
            image: "/sky2.webp",
            date: "26",
            day: "Wed",
            link:"/blog/sustainable-living-materials-that-everyone-is-rooting-for-nature-inclusive-living-for-a-better-tomorrow"
        },
        {
            title: "Remarkable evolution of north bangalore",
            description: "North Bangalore, once a dormant region, has transformed into a bustling nexus of growth and opportunity. The meteoric rise of this area as a prime real estate destination is not accidental; it's a result of strategic advantages and a confluence of key factors driving its exponential growth.",
            image: "/4.webp",
            date: "24",
            day: "Mon",
            link:"/blog/remarkable-evolution-of-north-bangalore-the-epicentre-of-growth-and-investment-opportunity"
        },
    ];

    return (
        <div className='Blogs-main-parent'>
            <div className="container-xxl">
                <div className='Blogs-sub-parent'>
                    <div className='Blogs-sub-parent-title text-center'>
                        <p>WHAT’S TRENDING</p>
                        <h1>Latest Blogs & Posts</h1>
                    </div>
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            }
                        }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                    >
                        {blogsData.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className='Blog1'>
                                    <div className='Blog1-sub'>
                                        <div className='Blog1-image'>
                                            <img src={blog.image} alt="" />
                                            <div className='Blogs-image-badge'>
                                                <h4>{blog.date} </h4>
                                                <p>{blog.day}</p>
                                            </div>
                                        </div>
                                        <h2>{blog.title.length > 30 ? blog.title.slice(0,30) + "..." : blog.title}</h2>
                                        <p>{blog.description.length > 120 ? blog.description.slice(0,120) + "..." : blog.description}</p>
                                        <Link to={blog.link}>
                                            <div className='Arrow-Image'>
                                                <img src="/Arrow 1.png" alt="" />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Blogs;
