import React, { useEffect, useState } from 'react'
import '../PropertyDetails/property.css'
import HomeNavbar from '../HomeNavbar'
import Footer from '../Footer'
import { useParams } from 'react-router-dom'
import OngoingProjects from "../../Components/JSON/OngoingProjects.json"
import PreviousProjects from '../../Components/JSON/PreviousProjects.json'
import UpcommingProjects from '../../Components/JSON/UpcommingProjects.json'
import { useDispatch } from 'react-redux'
import { SlugPropertyDetails } from '../../reducers/propertyReducer'
import OngoingPropertyBanner from './OngoingPropertyBanner'
import OngoingMarqueeSlider from './OngoingMarqueeSlider'
import OngoingTabsSection from './OngoingTabsSection'
import OngoingHighlights from './OngoingHighlights'
import OngoingLayouts from './OngoingLayouts'
import OngoingGalleyImages from './OngoingGalleyImages'
import OngoingAmenities from './OngoingAmenities'
import OngoingLocation from './OngoingLocation'
import FaqSection from '../PropertyDetails/FaqSection'
import OngoingAssetsComponent from './OngoingAssetsComponent'
import { Helmet } from 'react-helmet-async'
import PropertyVideo from '../PropertyDetails/PropertyVideo'
import HomeNewNavbar from '../HomeNewNavbar'
const OngoingPropertyDetails = () => {
  const slugDetails = useParams().slug;


  console.log("slugDetails",slugDetails)
  const dispatch = useDispatch();

  const [PropertyDetails, setPropertyDetails] = useState(null)
  
  const OngoingProjectsData = [...OngoingProjects, ...PreviousProjects, ...UpcommingProjects]
  

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(()=>{
    if(slugDetails){
      const data = OngoingProjectsData?.filter(item => item.slug === slugDetails)[0]
      setPropertyDetails(data)
      dispatch(SlugPropertyDetails(data))
    }
  },[slugDetails])

  return (
    <>
    <Helmet>
      <title>{PropertyDetails?.metaTitle}</title>
      <meta name='description' content={PropertyDetails?.metaDescription} data-rh="true" />
    </Helmet>
    <div className='PropertyDetails-main-parent'>
        <HomeNewNavbar/>
        <OngoingPropertyBanner/>
        <OngoingMarqueeSlider/>
        <OngoingTabsSection/>
        <OngoingHighlights/>
        <OngoingAssetsComponent slugDetails={slugDetails}/>
        <OngoingLayouts/>
        <OngoingGalleyImages/>
        <PropertyVideo />
        <OngoingAmenities/>
        <OngoingLocation/>
        <FaqSection/>
        <Footer/>
    </div>
    </>
  )
}

export default OngoingPropertyDetails