import React from 'react'

const AboutUs = () => {
    return (
        <>
            <div className='AboutUs-main-parent'>
                <div className="container-lg">
                <div className='row'>
                    <div className="col-md-6">
                        <div className="AboutUs-left-section">
                            <h2>Vision</h2>
                            <p>To redefine the future of living by creating sustainable, innovative, and luxurious spaces that inspire and elevate every moment of life</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="AboutUs-right-section">
                            <h2>Mission</h2>
                            <p>We create exceptional homes that blend cutting-edge design with eco-friendly practices, elevating lifestyles and building vibrant communities.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs