import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ContactFormModal from '../Contactus/ContactFormModal';
const WhygravityBanner = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='WhygravityBanner-parent'>
                <div className='Gavityblogpage-main-sub'>
                 <h1> <span>A Culture of</span>   COMFORT</h1>
                </div>
            </div>
          <div className="container-lg">
          <div className='banner-below-section-content'>
                <p>Each home we create with you reflects our founding values and how this journey that we've been taking together unfolded. With over a decade of experience and some credible projects to our name, we are moving forward with crafting experiences that offer more than just a living space— for homes that touch lives and stay with you for a lifetime.</p>
                <p>More than builders and developers, we see ourselves as creators of your reality where you trust us with the power to design your home, your world to the daily routines and rhythms of life. Kudos to our team of architects and equally inspiring collaborators who have continuously helped us pursue meaningful risks and challenges. Together, we aim to bring a subtle but strong shift in our industry by speaking of homes that belong with you.</p>
                <p>A delicate interplay between the indoor-outdoor, we approach living and incorporate sought-after sustainable and natural materials into our projects, sourcing everything from versatile Indian stones to renewable materials from across the globe to inspire dynamic perceptions of luxury. Welcome to a home that breathes with you.</p>
            </div>
          </div>
            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>
    )
}

export default WhygravityBanner