import React, { useEffect } from 'react'
import ContactBanner from './ContactBanner'
import '../Projects/Projects.css'
import HomeNavbar from '../HomeNavbar'
import Footer from '../Footer'
import './contact.css'
import ContactForm from './ContactForm'
import { Helmet } from 'react-helmet-async'
import HomeNewNavbar from '../HomeNewNavbar'
const Contactus = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <Helmet>
      <title>Gravity Homes - Contact</title>
      <meta name='description' content='Contact Gravity Homes For Details About Project Specifications And Amenities' data-rh="true" />
    </Helmet>
    <div className='Contactus-main-parent'  style={{ backgroundImage: `url('/contactbackground.png')`,backgroundRepeat:"no-repeat" }}>
        <HomeNewNavbar/>
        <ContactBanner/>
        <ContactForm/>
    </div>
        <Footer/>
    </>
  )
}

export default Contactus