import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const HomeNewNavbar = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openPC, setOpenPC] = useState(false);
    const [activeItem, setActiveItem] = useState(0);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const propertyData = useSelector(state => state?.property?.propertyData);

    console.log("propertyData", propertyData);
    const dropdownRefPC = useRef(null);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleOpenPC = () => {
        setOpenPC(!openPC);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutsidePC = (event) => {
        if (dropdownRefPC.current && !dropdownRefPC.current.contains(event.target)) {
            setOpenPC(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsidePC);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsidePC);
        };
    }, []);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const isHomePage = location.pathname === "/" || location.pathname === "/faqs" || location.pathname === "/career" || location.pathname === "/about";

    return (
        <>
            <style>
                {`
                .HomeNavbar-mainparent li.nav-item.active {
                    border: 1px solid #000;
                    border-radius: 20px;
                    padding: 0px 15px;
                }
                @media (max-width: 991px) {
                    .HomeNavbar-mainparent .navbar-collapse {
                        display: none !important;
                    }
                    .offcanvas {
                        display: block !important;
                    }
                }
                @media (min-width: 992px) {
                    .offcanvas {
                        display: none !important;
                    }
                }
                `}
            </style>
            <div className="HomeNavbar-mainparent">
                <div className="HomeNavbar-sub-parent">
                    <div className="container-xxl">
                        <nav className="navbar navbar-expand-lg">
                            <div className='Gravity-logo'>
                                <Link to="/">
                                    <img src="/newlogo-black.png" alt="" />
                                </Link>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className=" navbar-collapse" id="navbarSupportedContent">
                                <div className="row p-0 m-0 w-100 justify-content-end align-items-center">
                                    <div className="col-md-7">
                                        <ul className="navbar-nav mr-auto">
                                            <li className={`nav-item ${location.pathname === "/" ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/">Home</Link>
                                            </li>
                                            <li className={`nav-item ${location.pathname === "/about" ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/about">Why Gravity</Link>
                                            </li>
                                            <li className={`nav-item ${location.pathname === "/project" ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/project">Projects</Link>
                                            </li>
                                            <li className={`nav-item ${location.pathname === "/blog" || location.pathname === "/faqs" || location.pathname === "/invest-with-gravity" || location.pathname === "/career" ? 'active' : ''}`} ref={dropdownRefPC}>
                                                <Link onClick={handleOpenPC} className="nav-link dropdown-toggle" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded={open ? "true" : "false"}>
                                                    Resource
                                                </Link>
                                                {
                                                    openPC && (
                                                        <div className="dropdown-menu show" aria-labelledby="navbarDropdown">
                                                            <div className="dropdown-item margin-dropdown" onClick={() => navigate("/blog")}>Blogs</div>
                                                            <div className="dropdown-item margin-dropdown" onClick={() => navigate("/faqs")}>Faq's</div>
                                                            <div className="dropdown-item margin-dropdown" onClick={() => navigate("/invest-with-gravity")}>Investors</div>
                                                            <div className="dropdown-item margin-dropdown" onClick={() => navigate("/career")}>Careers</div>
                                                        </div>
                                                    )
                                                }
                                            </li>
                                            <li className={`nav-item ${location.pathname === "/contact" ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-inline my-2 my-lg-0">
                                            {isHomePage ? (
                                                <Link to="/contact">
                                                    <button className="btn my-2 my-sm-0" type="submit">Schedule A Site Visit</button>
                                                </Link>
                                            ) : (
                                                <a href="tel:(+91) 95389 44994">
                                                    <div className='contact-number-button'>
                                                        <button className="btn my-2 my-sm-0 d-flex gap-2 align-items-center" type="submit"><img src="/phoneicon-navbar.svg" alt="" /><span>+91 9538944994</span></button>
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                        <div className='Gravity-logo'>
                                            <Link to="/">
                                                <img src="/newlogo-black.png" alt="" />
                                            </Link>
                                        </div>
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav mr-auto">
                                        <li className={`nav-item ${location.pathname === "/" ? 'active' : ''}`}>
                                            <Link className="nav-link" to="/">Home</Link>
                                        </li>
                                        <li className={`nav-item ${location.pathname === "/about" ? 'active' : ''}`}>
                                            <Link className="nav-link" to="/about">Why Gravity</Link>
                                        </li>
                                        <li className={`nav-item ${location.pathname === "/project" ? 'active' : ''}`}>
                                            <Link className="nav-link" to="/project">Projects</Link>
                                        </li>
                                        <li className={`nav-item ${activeItem === 3 ? 'active' : ''}`} ref={dropdownRef}>
                                            <a onClick={handleOpen} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded={open ? "true" : "false"}>
                                                Resource
                                            </a>
                                            {
                                                open && (
                                                    <div className="dropdown-menu show" aria-labelledby="navbarDropdown">
                                                        <a className="dropdown-item" href="/blog">Blogs</a>
                                                        <a className="dropdown-item" href="/faqs" >Faq's</a>
                                                        <a className="dropdown-item" href=" /invest-with-gravity">Investors</a>
                                                        <a className="dropdown-item" href="/career">Careers</a>
                                                    </div>
                                                )
                                            }
                                        </li>
                                        <li className={`nav-item ${location.pathname === "/contact" ? 'active' : ''}`}>
                                            <Link className="nav-link" to="/contact">Contact</Link>
                                        </li>
                                        <li className={`nav-item  ${location.pathname === "/contact" ? 'active' : ''}`}>
                                            <Link className="nav-link" to="/rera-disclaimer">Rera Disclaimer</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 navbar-header-button">
                                    <div className="form-inline my-2 my-lg-0">
                                        {isHomePage ? (
                                            <Link to="/contact">
                                                <button className="btn my-2 my-sm-0" type="submit">Schedule A Site Visit</button>
                                            </Link>
                                        ) : (
                                            <a href="tel:(+91) 95389 44994">
                                                <div className='contact-number-button'>
                                                    <button className="btn my-2 my-sm-0 d-flex gap-2 align-items-center" type="submit"><img src="/phoneicon-navbar.svg" alt="" /><span>+91 9538944994</span></button>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeNewNavbar;
