import React, { useState } from "react";
import ContactFormModal from "./Contactus/ContactFormModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard, Autoplay, Pagination } from "swiper/modules";
import SwiperCore from "swiper/core";

SwiperCore.use([Pagination, Autoplay, Mousewheel, Keyboard]);

const OurStrengths = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="OurStrengths-parent">
        <div className="container-xxl">
          <div className="OurStrengths-sub-parent">
            <div className="OurStrengths-Title">
              <h1>Our Gallery</h1>
            </div>
            <Swiper
              spaceBetween={50}
              slidesPerView={3}
              loop={true}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay, Mousewheel, Keyboard]}
              className="banner-swiper"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            >
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/home-gallery-1.jpg"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/home-gallery-2.jpg"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/home-gallery-3.jpg"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/home-gallery-4.jpg"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/ProjectImages/lakesideimg8.webp"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/ProjectImages/lakesideimg9.webp"//updated
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/Living_Materials with Life3.webp"//updated
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/G_Sunridge5.webp"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: "100%", height: "80%" }}
                  src="/Living_Materials with Life1.webp"
                  alt=""
                />
              </SwiperSlide>            
            </Swiper>
          </div>
        </div>
      </div>
      <ContactFormModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default OurStrengths;
