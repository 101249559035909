import React, { useState } from "react";
import { Link } from "react-router-dom";
import HomeNavbar from "./HomeNavbar";
import { Modal } from "antd";
import ContactFormModal from "./Contactus/ContactFormModal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard, Autoplay } from "swiper/modules";
import SwiperCore from "swiper/core";

SwiperCore.use([Autoplay, Mousewheel, Keyboard]);

const HomeNewBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="HomeBanner-parent">
        <HomeNavbar />
        <div className="container-flui">
          <div className="HomeBanner-sub-parent">
            <div
              className="Bannerbackground-image"
              style={{ backgroundImage: `url('/Line pattern.png')` }}
            ></div>
            <div className="">
              <div className="col-md-12">
                <div className="HomeBanner-title">
                  <h1>Experience the epitome of home comfort.</h1>
                  {/* <Link to="#" onClick={showModal}>
                                        <div className='BannerbuttonImage-parent' >
                                            <div className='BannerbuttonImage'>
                                                <img src="/bannerhome.svg" alt="" />
                                            </div>
                                            <span>Schedule A Site Visit</span>
                                        </div>
                                    </Link> */}
                </div>
                <div className="HomeBanner-image-main">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Mousewheel, Keyboard]}
                    className="banner-swiper"
                  >
                    <SwiperSlide>
                      <img src="/banner-first-image.webp" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/Living Palette_1.webp" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/Living Palette_2.webp" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/h1.webp" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/interior1.webp" alt="" />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                                            <img src="/h2.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/h3.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/skyforest.jpg" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/estate2.jpg" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/banner_2.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/Project_smera_banner.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/grvityaranyaupdate.jpeg" alt="" />
                                        </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactFormModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default HomeNewBanner;
