import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { message, Modal, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const OngoingAssetsComponent = ({ slugDetails }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [documentType, setDocumentType] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phonenumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.email.trim()) errors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
    if (!formData.subject.trim()) errors.subject = "Subject is required";
    if (!formData.phonenumber.trim()) errors.phonenumber = "Phone number is required";
    if (!/^\d{10}$/.test(formData.phonenumber))
      errors.phonenumber = "Phone number is invalid";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      message.loading("Please wait! Your response is sending.");
      try {
        const response = await fetch(
          "https://gravityhomes.in/forms/contact.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (response.ok) {
          if (data.status === "success") {
            // Handle successful form submission
            setModalMessage(
              "Form submitted successfully. You can now download the document."
            );
            downloadFile();
            window.location.href =
              "https://gravityhomes.in/forms/thankyou.html";
          } else {
            message.error("An error occurred. Please try again.");
            setModalMessage(
              data.message || "An error occurred. Please try again."
            );
          }
        } else {
          message.error("An error occurred. Please try again.");
          setModalMessage(
            data.message || "An error occurred. Please try again."
          );
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        message.error("Error submitting form.");
        setModalMessage("An error occurred. Please try again later.");
      }
    } else {
      setErrors(formErrors);
      message.error("Please correct the errors in the form.");
      setModalMessage("Please correct the errors in the form.");
    }

    setIsLoading(false);
  };

  const downloadFile = () => {
    let filePath = "";

    if (documentType === "modal1" && slugDetails === "gravity-aranya")
        filePath = "/brouchers/G_Aranya_E-Brochure.pdf";
      if (documentType === "modal2" && slugDetails === "gravity-aranya")
        filePath = "/master-plans/G_Aranya_Master_plan.pdf";
      if (documentType === "modal3" && slugDetails === "gravity-aranya")
        filePath = "/floor-plans/G_Aranya_Floor_Plans_plan.pdf";
      if (documentType === "modal1" && slugDetails === "gravity-smera")
        filePath = "/brouchers/Gra_Smera_Brochure.pdf";
      if (documentType === "modal2" && slugDetails === "gravity-smera")
        filePath =
          "/master-plans/Smera_Gardens_Brochure_Layout-master_plan.pdf";
      if (documentType === "modal3" && slugDetails === "gravity-smera")
        filePath = "/floor-plans/Smera_Gardens_Layout-Floor_Plan.pdf";
      if (documentType === "modal1" && slugDetails === "gravity-thanisandra")
        filePath = "/brouchers/G_sky_forest_Brochure_1.pdf";
      if (documentType === "modal2" && slugDetails === "gravity-thanisandra")
        filePath = "/master-plans/SKY_FOREST_MASTER_PLAN.pdf";
      if (documentType === "modal3" && slugDetails === "gravity-thanisandra")
        filePath = "/floor-plans/sky_Forest_Floor_Plan.pdf";
      if (documentType === "modal1" && slugDetails === "gravity-rajankunte")
        filePath = "/brouchers/G_sky_forest_Brochure_1.pdf";
      if (documentType === "modal2" && slugDetails === "gravity-rajankunte")
        filePath = "/master-plans/master_planlake_estate.pdf";
      if (documentType === "modal3" && slugDetails === "gravity-rajankunte")
        filePath = "/floor-plans/Lake_Estate_floor_plan_UPDATED_1.pdf";

    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = filePath.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const showModal = (modal) => {
    if (modal === "modal1") setIsModalOpen1(true);
    if (modal === "modal2") setIsModalOpen2(true);
    if (modal === "modal3") setIsModalOpen3(true);
    setDocumentType(modal);
  };

  const handleOk = () => {
    setIsModalOpen1(false);
    setIsModalOpen2(false);
    setIsModalOpen3(false);
  };

  const handleCancel = () => {
    setIsModalOpen1(false);
    setIsModalOpen2(false);
    setIsModalOpen3(false);
  };

  const renderModal = (modalId, title) => (
    <Modal
      footer={null}
      title={title}
      open={
        modalId === "modal1"
          ? isModalOpen1
          : modalId === "modal2"
          ? isModalOpen2
          : isModalOpen3
      }
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <form onSubmit={handleSubmit}>
        <div className="ContactForm-sub-right">
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {errors.name && (
                <p className="error text-danger">{errors.name}</p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && (
                <p className="error text-danger">{errors.email}</p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
              {errors.subject && (
                <p className="error text-danger">{errors.subject}</p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="phonenumber"
                placeholder="Phone"
                value={formData.phonenumber}
                onChange={handleInputChange}
                required
              />
              {errors.phonenumber && (
                <p className="error text-danger">{errors.phonenumber}</p>
              )}
            </div>
            <div className="col-md-12">
              <textarea
                name="message"
                placeholder="Hello I am interested in..."
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </div>
            <input type="hidden" id="currentUrlInput" name="currentUrl" />
            <input type="hidden" name="documentType" value={documentType} />
            <input type="hidden" name="slug" value={slugDetails} />
            <button
              type="submit"
              disabled={isLoading}
              className={isLoading ? "loading" : ""}
            >
              {isLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Send Now ➜"
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlInput = document.getElementById("currentUrlInput");
    if (urlInput) {
      urlInput.value = currentUrl;
    }
  }, [isModalOpen1, isModalOpen2, isModalOpen3]);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     if (urlParams.has("download") && urlParams.get("download") === "true") {
//       const documentType = urlParams.get("type");
//       const slug = urlParams.get("slug");
//       let filePath = "";
//       if (documentType === "modal1" && slug === "gravity-aranya")
//         filePath = "/brouchers/G_Aranya_E-Brochure.pdf";
//       if (documentType === "modal2" && slug === "gravity-aranya")
//         filePath = "/master-plans/G_Aranya_Master_plan.pdf";
//       if (documentType === "modal3" && slug === "gravity-aranya")
//         filePath = "/floor-plans/G_Aranya_Floor_Plans_plan.pdf";
//       if (documentType === "modal1" && slug === "gravity-smera")
//         filePath = "/brouchers/Gra_Smera_Brochure.pdf";
//       if (documentType === "modal2" && slug === "gravity-smera")
//         filePath =
//           "/master-plans/Smera_Gardens_Brochure_Layout-master_plan.pdf";
//       if (documentType === "modal3" && slug === "gravity-smera")
//         filePath = "/floor-plans/Smera_Gardens_Layout-Floor_Plan.pdf";
//       if (documentType === "modal1" && slug === "gravity-thanisandra")
//         filePath = "/brouchers/G_sky_forest_Brochure_1.pdf";
//       if (documentType === "modal2" && slug === "gravity-thanisandra")
//         filePath = "/master-plans/SKY_FOREST_MASTER_PLAN.pdf";
//       if (documentType === "modal3" && slug === "gravity-thanisandra")
//         filePath = "/floor-plans/sky_Forest_Floor_Plan.pdf";
//       if (documentType === "modal1" && slug === "gravity-rajankunte")
//         filePath = "/brouchers/G_sky_forest_Brochure_1.pdf";
//       if (documentType === "modal2" && slug === "gravity-rajankunte")
//         filePath = "/master-plans/master_planlake_estate.pdf";
//       if (documentType === "modal3" && slug === "gravity-rajankunte")
//         filePath = "/floor-plans/Lake_Estate_floor_plan_UPDATED_1.pdf";

//       if (filePath) {
//         const link = document.createElement("a");
//         link.href = filePath;
//         link.download = filePath.split("/").pop();
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//         window.history.pushState({}, document.title, window.location.pathname);
//       }
//     }
//   }, []);

  return (
    <>
      <div className="AssetsComponent-main-parent" id="Asset">
        <div className="AssetsComponent-sub-parent">
          <h1>Assets - </h1>
          <ul className="Assets-tabs-parent" id="Pricings">
            <li
              className={` ${activeTab === "tab1" ? "active" : ""}`}
              onClick={() => handleTabClick("tab1")}
              style={{ cursor: "pointer" }}
            >
              Broucher
            </li>
            <li
              className={` ${activeTab === "tab2" ? "active" : ""}`}
              onClick={() => handleTabClick("tab2")}
              style={{ cursor: "pointer" }}
            >
              Master Plan
            </li>
            <li
              className={` ${activeTab === "tab3" ? "active" : ""}`}
              onClick={() => handleTabClick("tab3")}
              style={{ cursor: "pointer" }}
            >
              Floor Plan
            </li>
          </ul>
          <div className="tab-content Assets-tabs-content-parent">
            {activeTab === "tab1" && (
              <div className="Assetstab1-parent">
                {renderModal(
                  "modal1",
                  "Fill and Submit the form to Download Brochure"
                )}
                <Link to="#" onClick={() => showModal("modal1")}>
                  <div className="TabsSection-right-sub1-pdf">
                    <img src="/broucher.webp" loading="lazy" alt="" />
                  </div>
                </Link>
              </div>
            )}
            {activeTab === "tab2" && (
              <div className="Assetstab1-parent">
                {renderModal(
                  "modal2",
                  "Fill and Submit the form to Download Master Plan"
                )}
                <Link to="#" onClick={() => showModal("modal2")}>
                  <div className="TabsSection-right-sub1-pdf">
                    <img src="/master-plan.webp" loading="lazy" alt="" />
                  </div>
                </Link>
              </div>
            )}
            {activeTab === "tab3" && (
              <div className="Assetstab1-parent">
                {renderModal(
                  "modal3",
                  "Fill and Submit the form to Download Floor Plan"
                )}
                <Link to="#" onClick={() => showModal("modal3")}>
                  <div className="TabsSection-right-sub1-pdf">
                    <img src="/floor.webp" loading="lazy" alt="" />
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingAssetsComponent;
