import React, { useEffect } from 'react'
import HomeNavbar from './HomeNavbar'
import "./Home.css"
import HomeBanner from './HomeBanner'
import HomeAboutus from './HomeAboutus'
import HomeProjects from './HomeProjects'
import Blogs from './Blogs'
import OurStrengths from './OurStrengths'
import Footer from './Footer'
import Testimonials from './Testimonials'
import { Helmet } from 'react-helmet-async'
import FAQ from './FaqComponents/FAQ'
import Faqconatct from './FaqComponents/Faqconatct'
import HomeFaq from './FaqComponents/HomeFaq'
import HomeNewBanner from './HomeNewBanner'
const Homepage = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
        <Helmet>
      <title>Gravity Homes Real Estate - Luxury Villas, Villaments and Apartments in Bangalore</title>
      <meta name='description' content='Gravity Homes Leading Villa, Villaments And Apartment Developers In Bangalore Offers Your 3 5 And 4 BHK Villas And Independent Homes With Premium Class Amenities' data-rh="true" />
    </Helmet>
            <div className='Homepage-main-parent'>
                {/* <HomeNavbar /> */}
                {/* <HomeBanner/> */}
                <HomeNewBanner/>
                <HomeAboutus/>
                <HomeProjects/>
                <Testimonials/>
                <Blogs/>
                <OurStrengths/>
                {/* <HomeFaq />
                <Faqconatct /> */}
                <Footer/>
            </div>
        </>

    )
}

export default Homepage