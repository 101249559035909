import React, { useEffect, useState } from 'react';
import './Blogdetails.css';
import HomeNavbar from '../HomeNavbar';
import Footer from '../Footer';
import Blogsdata from "../JSON/Blogsdata.json";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { SlugPropertyDetails } from '../../reducers/propertyReducer';
import { Helmet } from 'react-helmet-async';
import HomeNewNavbar from '../HomeNewNavbar';

const BlogDetails = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const slugDetails = useParams().slug;
  const dispatch = useDispatch();
  const [PropertyDetails, setPropertyDetails] = useState(null);

  const BlogsdataJSON = Blogsdata;

  useEffect(() => {
    if (slugDetails) {
      const data = BlogsdataJSON?.filter(item => item.slug === slugDetails)[0];
      setPropertyDetails(data);
      dispatch(SlugPropertyDetails(data));
    }
  }, [slugDetails, dispatch, BlogsdataJSON]);

  const propertyData = useSelector(state => state?.property?.propertyData);

  // Filter out the current blog post
  const relatedPosts = BlogsdataJSON.filter(post => post.slug !== slugDetails).slice(0, 3);
  console.log("relatedPosts",relatedPosts)
  return (
    <>
      <Helmet>
        <title>{PropertyDetails?.metaTitle || ''}</title>
        <meta name='description' content={PropertyDetails?.metaDescription || ''} data-rh="true" />
      </Helmet>
      <div className="BlogDetails-main-parent">
        <HomeNewNavbar />
        <div className='BlogDetailsInner-parent position-relative'>
          <div className='Blogdetails-main-head'>
            <div className="Blogdetails-img-main">
              <img src={PropertyDetails?.mainimage || ''} alt="" />
            </div>
            <div className="BlogDetails-Finibus-Bonorum-line-main">
              <div className="BlogDetails-Finibus-Bonorum-line-head-sub">
                {PropertyDetails?.title?.length > 0 && <h2>{PropertyDetails.title}</h2>}
                <p>Written by Gravity Homes</p>
                <hr />
              </div>
              <div className="BlogDetails-Finibus-Bonorum-sub-lines">
                {
                  PropertyDetails?.body?.map((data, index) => (
                    <div 
                      key={index} 
                     
                      dangerouslySetInnerHTML={{ __html: data }} 
                    />
                  ))
                }
              </div>
            </div>
            <div className="Gavityblogpage-popular-word">
              <h5>Related Blogs</h5>
            </div>
            <div className="row Gavityblogpage-Articles-News-main">
              {relatedPosts.map(post => (
                <div key={post.id} className="col-lg-4 col-sm-6 col-md-6">
                  <Link to={`/blog/${post.slug}`} onClick={handleClick}>
                    <div className="Gavityblogpage-Articles-card-main">
                      <div className="Gavityblogpage-Articles-card-img">
                        <img src={post.mainimage || ''} alt="" />
                        <h6 className="blogs-category-badge">{post.category || ''}</h6>
                      </div>
                      <h4>{post.title?.length > 0 ? post.title.slice(0, 60) + "..." : ''}</h4>
                      <div className="Gavityblogpage-Articles-card-date-and-arrow">
                        <p>{post.date || ''}</p>
                        <div className="Gavityblogpage-block-two-popular-arrow-logo-two">
                          <Link to={`/blog/${post.slug}`}>
                            <img src="/gavityphotos/Arrowimg.svg" alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className='BlogDetails-scrollTop' onClick={scrollTop}>
              <img src="/scrolltop.svg" alt="" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogDetails;
