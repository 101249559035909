import React from 'react'

const FounderInspirations = () => {
  return (
    <>
    <div className="FounderInspirations-parent">
        <div className="container-lg">
            <div className='FounderInspirations-title'>
                <h2><span className='text-dark'>Founder</span> INSPIRATIONS</h2>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="FounderInspirations-left">
                        <p>Surya N.C., born to farmer parents in the small town of Devanahalli, began his career in the exports industry before transitioning into real estate. Despite being new to the field, he quickly recognized the potential in the villa and villament sector, which inspired him to establish Gravity in 2008, with a focus on innovation and quality in residential spaces. Alongside his real estate ventures, Surya, who holds a Bachelor of Engineering (BE) degree, also founded Gravity BPO Solutions, further expanding his business portfolio and demonstrating his commitment to growth and diversification.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="FounderInspirations-left">
                        <p>Growing up in a family of farmers shaped his perspective as he started developing, exploring and maximising spatial comfort, co-creating with nature and incorporating what he calls, “Materials with Life”. He truly believes that nature is not only a pleasant feature in our homes but a key factor that affects our everyday productivity and brings positivity. Transforming the whole experience, his vision is to create live spaces, community culture and vibrancy that inspires and enriches lives with quality investments to their future with Gravity Living.</p>
                    </div>
                </div>
            </div>

            <div className='Ceo-testimonial'>
                <div className='Ceo-testimonial-image'>
                    <img src="./aboutus-quotes.png" alt="" />
                </div>
                <p>“We all know that home is a feeling. Home is so many things. Your home seeks a connection with the world outside, and if a home can inspire and motivate your life choices, that is the best thing, I believe.”</p>
                <h4>~ Surya N.C.</h4>
                <span>Founder & CEO</span>
            </div>
        </div>
    </div>
    </>
  )
}

export default FounderInspirations