import React, { useEffect } from 'react'
import WhygravityLeadership from './WhygravityLeadership'
import './whygravity.css'
import AskSection from './AskSection'
import WhygravityBanner from './WhygravityBanner'
import AboutUs from './AboutUs'
import Footer from '../Footer'
import HomeNavbar from '../HomeNavbar'
// import RecentProjects from './RecentProjects'
import { Helmet } from 'react-helmet-async'
import WhyUs from '../Projects/WhyUs'
import FounderInspirations from './FounderInspirations'
const Whygravity = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <Helmet>
      <title>Gravity Homes - About Us</title>
      <meta name='description' content='Discover the story behind Gravity Real Estate Our mission is to redefine luxury living in Bangalore Learn more about us' data-rh="true" />
    </Helmet>
      <div className='WhyGravity-main-page'>
        <HomeNavbar />
        <WhygravityBanner />
        <FounderInspirations/>
        <AboutUs />
        {/* <RecentProjects /> */}
        {/* <WhyUs /> */}
        <WhygravityLeadership />
        <AskSection />
        <Footer />
      </div>
    </>
  )
}

export default Whygravity