import React, { useEffect, useRef, useState } from 'react'
// import HomeNavbar from '../HomeNavbar'
import "../InvestGravity/Invest.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { Modal } from 'antd';
import ContactFormModal from '../Contactus/ContactFormModal';
import { Helmet } from 'react-helmet-async';
import HomeNewNavbar from '../HomeNewNavbar';
const InvestGravity = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0)

    const [blockHeight, setBlockHeight] = useState(465);
    const [block1Height, setBlock1Height] = useState(465);
    const [block2Height, setBlock2Height] = useState(465);


    const contentRef = useRef();
    const [refHeight, setRefHeight] = useState(0)
    const defaultBlockHeight = 465;
    useEffect(() => {
        // Update the height of the content when it changes
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [isExpanded])

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
        setIsExpanded2(false);
        setIsExpanded3(false);
        setBlockHeight(!isExpanded ? 680 : defaultBlockHeight)

        setBlock1Height(defaultBlockHeight)
        setBlock2Height(defaultBlockHeight)
    };

    /*second card */
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [contentHeight2, setContentHeight2] = useState(0)
    const contentRef2 = useRef();
    useEffect(() => {
        // Update the height of the content when it changes
        if (contentRef2.current) {
            setContentHeight2(contentRef2.current.scrollHeight);
        }
    }, [isExpanded2])

    const toggleExpansion2 = () => {
        setIsExpanded2(!isExpanded2);
        setIsExpanded3(false);
        setIsExpanded(false);

        setBlockHeight(!isExpanded2 ? 680 : defaultBlockHeight)

        setBlock1Height(defaultBlockHeight)
        setBlock2Height(defaultBlockHeight)

    };


    /*thied card */
    const [isExpanded3, setIsExpanded3] = useState(false);
    const [contentHeight3, setContentHeight3] = useState(0)
    const contentRef3 = useRef();
    useEffect(() => {
        // Update the height of the content when it changes
        if (contentRef3.current) {
            setContentHeight3(contentRef3.current.scrollHeight);
            setRefHeight(contentRef3.current.clientHeight);
            setContentHeight2(contentRef3.current.scrollHeight);
        }
    }, [isExpanded3])

    const toggleExpansion3 = () => {

        setIsExpanded3(!isExpanded3);
        setIsExpanded(false);
        setIsExpanded2(false);
        setBlockHeight(!isExpanded3 ? 830 : defaultBlockHeight)
        setBlock1Height(defaultBlockHeight)
        setBlock2Height(defaultBlockHeight)
    };
    const maxHeight = 265;


    console.log("contentHeight", contentHeight, "contentHeight2", contentHeight2, "contentRef3", contentRef3)


    /*  juhygtfrdesa mobile*/

    const [isExpandedM1, setIsExpandedM1] = useState(false);
    const [contentHeightM1, setContentHeightM1] = useState(0)
    const contentRefM1 = useRef();
    useEffect(() => {
        // Update the height of the content when it changes
        if (contentRefM1.current) {
            setContentHeightM1(contentRefM1.current.scrollHeight);
        }
    }, [isExpandedM1])

    const toggleExpansionM1 = () => {
        setIsExpandedM1(!isExpandedM1);
    };

    /*second card */
    const [isExpandedM2, setIsExpandedM2] = useState(false);
    const [contentHeightM2, setContentHeightM2] = useState(0)
    const contentRefM2 = useRef();
    useEffect(() => {
        // Update the height of the content when it changes
        if (contentRefM2.current) {
            setContentHeightM2(contentRefM2.current.scrollHeight);
        }
    }, [isExpandedM2])

    const toggleExpansionM2 = () => {
        setIsExpandedM2(!isExpandedM2);
    };


    /*thied card */
    const [isExpandedM3, setIsExpandedM3] = useState(false);
    const [contentHeightM3, setContentHeightM3] = useState(0)
    const contentRefM3 = useRef();
    useEffect(() => {
        // Update the height of the content when it changes
        if (contentRefM3.current) {
            setContentHeightM3(contentRefM3.current.scrollHeight);
        }
    }, [isExpandedM3])

    const toggleExpansionM3 = () => {

        setIsExpandedM3(!isExpandedM3);
    };
    const maxHeightM = 265;

    useEffect(() => {
        window.scrollTo()
    })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
        <Helmet>
      <title>Gravity Homes - Invest with Gravity Real Estate</title>
      <meta name='description' content='Explore lucrative real estate investment opportunities with Gravity Invest wisely for a secure and rewarding future' data-rh="true" />
    </Helmet>
        <div className="InvestGravity-grandparent">
            <div className='InvestGravity-parent' style={{ backgroundImage: `url('/contactbackground.png')`, backgroundRepeat: "no-repeat" }}>
                <HomeNewNavbar />
                <div className="InvestGravity-subparent">
                    <h2>INVEST WITH <span> GRAVITY</span></h2>
                </div>
            </div>
            <div className='gravity-bannerbelow-parent'>
                <div className='bannerbelow-parent'>
                    <h1>“Beyond Walls,
                        We Build Homes”</h1>
                    <h5>BUILDING A COMMUNITY</h5>
                </div>
                <div className="container">
                    <div className='row m-0 p-0'>
                        <div className="col-md-4 col-6">
                            <div className="BUILDING-A-COMMUNITY">
                                <div className='BUILDING-A-COMMUNIT-imge'>
                                    <img src="/home1.svg" alt="" />
                                </div>
                                <div className='BUILDING-A-COMMUNIT-text'>
                                    <h6>Affordable Luxury Living </h6>
                                    <p>A Unique Nature-Inclusive Model for Enriched Lifesyles Redefining The Urban Green</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6">
                            <div className="BUILDING-A-COMMUNITY">
                                <div className='BUILDING-A-COMMUNIT-imge'>
                                    <img src="/home4.svg" alt="" />
                                </div>
                                <div className='BUILDING-A-COMMUNIT-text'>
                                    <h6>Sustainability at the Core</h6>
                                    <p>Locally Sourced Natural Impact: Versatile Indian Kota Stones, Traditional Mud Blocks etc.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6">
                            <div className="BUILDING-A-COMMUNITY">
                                <div className='BUILDING-A-COMMUNIT-imge'>
                                    <img src="/home3.svg" alt="" />
                                </div>
                                <div className='BUILDING-A-COMMUNIT-text'>
                                    <h6>Experience Calm within City Limits</h6>
                                    <p>Tucked Away from the Noise, Sustainable Luxury Living & No Surging Maintenance Costs</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6">
                            <div className="BUILDING-A-COMMUNITY">
                                <div className='BUILDING-A-COMMUNIT-imge'>
                                    <img src="/home2.svg" alt="" />
                                </div>
                                <div className='BUILDING-A-COMMUNIT-text'>
                                    <h6>Proven Appreciation & Long-Term Value </h6>
                                    <p>Proven Value Appreciation (60%-100%)* and Guaranteed Long-Term Returns</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6">
                            <div className="BUILDING-A-COMMUNITY">
                                <div className='BUILDING-A-COMMUNIT-imge'>
                                    <img src="/home5.svg" alt="" />
                                </div>
                                <div className='BUILDING-A-COMMUNIT-text'>
                                    <h6>Seamless Connectivity and Convenience</h6>
                                    <p>Strategically Positioned: Maximised & Hassle-Free Location Advantage</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6">
                            <div className="BUILDING-A-COMMUNITY">
                                <div className='BUILDING-A-COMMUNIT-imge'>
                                    <img src="/home6.svg" alt="" />
                                </div>
                                <div className='BUILDING-A-COMMUNIT-text'>
                                    <h6>Resale & Future Opportunities</h6>
                                    <p>An Average of N% High Resale Potential</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='investgravity-blogs-width'>
                <div className='container fgbhnjm'>
                    <div className='investgravity-blogs-text'>
                        <h2>ESTEEMED <span>INVESTORS</span></h2>
                    </div>
                    {/* <div className='row p-0 m-0 ESTEEMED-row-dispaly'>
                        <div className="col-md-4">
                            <div style={{ height: `${blockHeight}px` }} className='investgravity-returndetails'>
                                <p>PROCESS & AGREEMENT</p>
                                <ol ref={contentRef}
                                    style={{
                                        maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                    }}
                                    
                                    >
                                    <li>Only an existing project property will be offered under the Sale Agreement.</li>
                                    <li>Cancellation/Rebooking is applicable only after successful Sanctioned Approvals.</li>
                                    <li>Once Sanctioned, Investors can sell the property to their contacts for potentially higher and quicker return rates.</li>
                                    <li>Please note, the Selling Price will be decided as per The Prevailing Market Rate for enhanced market compatibility.</li>


                                </ol>
                                {
                                    contentHeight > maxHeight && (
                                        <button onClick={toggleExpansion}>
                                            <div className="toggleExpansion-showless">
                                                {isExpanded ? 'Show Less' : 'Show More'}
                                                <FaArrowRightLong />
                                            </div>
                                        </button>
                                    )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div style={{ height: `${block1Height}px` }} className='investgravity-returndetails'>
                                <p>RETURNS ON BUY-BACK</p>
                                <ol
                                    ref={contentRef2}
                                    style={{
                                        maxHeight: isExpanded2 ? 'none' : `${maxHeight}px`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                    }}
                                    >
                                    <li>Minimum 30% Return Guaranteed Buy-Back for a secure investment with Gravity Homes.</li>
                                    <li>Fixed Term Advantage: A reserved 2-year period is valid for your Sanctioned offer.</li>
                                    <li>Villa Ownership Simplified: Hassle-Free Villa Acquisition through our Assured Sale Agreement as mentioned in 1.</li>
                                    <li>Professional Peace of Mind: Gravity Homes is Committed to Providing a Straighforward, Secure and Sound Investment Plan.</li>
                                </ol>
                                {
                                    contentHeight2 > maxHeight && (
                                        <button onClick={toggleExpansion2} >
                                            <div className="toggleExpansion-showless">
                                                {isExpanded2 ? 'Show Less' : 'Show More'}
                                                <FaArrowRightLong />
                                            </div>
                                        </button>
                                    )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div style={{ height: `${block2Height}px` }} className='investgravity-returndetails'>
                                <p>INVESTMENT PARTNERING</p>
                                <ol ref={contentRef3}
                                    style={{
                                        maxHeight: isExpanded3 ? 'none' : `${maxHeight}px`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                    }}>
                                    <li>Enhanced Investment Opportunities: Exclusive Property Mortgage Offering for all investors committing an investment sum of 5 crore or more.</li>
                                    <li>Streamlined Sales Process: Once the sale is concluded, a prompt No Objection Certificate (NOC) will be obtained from the investor, similar to all standard procedures followed with the banks.</li>
                                    <li>Tailored Returns: A range of return plans available for individual consultations.</li>
                                    <li>Personalised Financial Goals: Gravity Homes is Committed to Providing Steady Investment Opportunities Personalised to Your Financial Goals.</li>
                                </ol>
                                {
                                    contentHeight3 > maxHeight && (
                                        <button onClick={toggleExpansion3} className="">
                                            <div className="toggleExpansion-showless">
                                                {isExpanded3 ? 'Show Less' : 'Show More'}
                                                <FaArrowRightLong />
                                            </div>
                                        </button>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className='row p-0 m-0 ESTEEMED-row-mobiledispaly'>
                        <div className="col-md-4">
                            <div className='investgravity-returndetails'>
                                <p>PROCESS & AGREEMENT</p>
                                <ol ref={contentRefM1}
                                    style={{
                                        maxHeight: isExpandedM1 ? 'none' : `${maxHeight}px`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                    }}
                                    
                                    >
                                    <li>Only an existing project property will be offered under the Sale Agreement.</li>
                                    <li>Cancellation/Rebooking is applicable only after successful Sanctioned Approvals.</li>
                                    <li>Once Sanctioned, Investors can sell the property to their contacts for potentially higher and quicker return rates.</li>
                                    <li>Please note, the Selling Price will be decided as per The Prevailing Market Rate for enhanced market compatibility.</li>
                                </ol>
                                {
                                    contentHeightM1 > maxHeight && (
                                        <button onClick={toggleExpansionM1}>
                                            <div className="toggleExpansion-showless">
                                                {isExpandedM1 ? 'Show Less' : 'Show More'}
                                                <FaArrowRightLong />
                                            </div>
                                        </button>
                                    )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='investgravity-returndetails'>
                                <p>RETURNS ON BUY-BACK</p>
                                <ol
                                    ref={contentRefM2}
                                    style={{
                                        maxHeight: isExpandedM2 ? 'none' : `${maxHeight}px`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                    }}
                                    >
                                    <li>Minimum 30% Return Guaranteed Buy-Back for a secure investment with Gravity Homes.</li>
                                    <li>Fixed Term Advantage: A reserved 2-year period is valid for your Sanctioned offer.</li>
                                    <li>Villa Ownership Simplified: Hassle-Free Villa Acquisition through our Assured Sale Agreement as mentioned in 1.</li>
                                    <li>Professional Peace of Mind: Gravity Homes is Committed to Providing a Straighforward, Secure and Sound Investment Plan.</li>
                                </ol>
                                {
                                    contentHeightM2 > maxHeight && (
                                        <button onClick={toggleExpansionM2} >
                                            <div className="toggleExpansion-showless">
                                                {isExpandedM2 ? 'Show Less' : 'Show More'}
                                                <FaArrowRightLong />
                                            </div>
                                        </button>
                                    )}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='investgravity-returndetails'>
                                <p>INVESTMENT PARTNERING</p>
                                <ol ref={contentRefM3}
                                    style={{
                                        maxHeight: isExpandedM3 ? 'none' : `${maxHeight}px`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.3s ease',
                                    }}>
                                    <li>Enhanced Investment Opportunities: Exclusive Property Mortgage Offering for all investors committing an investment sum of 5 crore or more.</li>
                                    <li>Streamlined Sales Process: Once the sale is concluded, a prompt No Objection Certificate (NOC) will be obtained from the investor, similar to all standard procedures followed with the banks.</li>
                                    <li>Tailored Returns: A range of return plans available for individual consultations.</li>
                                    <li>Personalised Financial Goals: Gravity Homes is Committed to Providing Steady Investment Opportunities Personalised to Your Financial Goals.</li>
                                </ol>
                                {
                                    contentHeightM3 > maxHeight && (
                                        <button onClick={toggleExpansionM3} className="">
                                            <div className="toggleExpansion-showless">
                                                {isExpandedM3 ? 'Show Less' : 'Show More'}
                                                <FaArrowRightLong />
                                            </div>
                                        </button>
                                    )}
                            </div>
                        </div>
                    </div> */}
                    <div className='contact-us-investgravity'>
                        <Link to="#" onClick={showModal}>
                            <div className='contact-us-invest'>
                                <h6>Consult Now</h6>
                                <FaArrowRightLong />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                />
            <Footer />
        </div>
                </>
    )
}

export default InvestGravity